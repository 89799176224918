// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-contact-address-img-js": () => import("./../../../src/pages/contact/AddressImg.js" /* webpackChunkName: "component---src-pages-contact-address-img-js" */),
  "component---src-pages-contact-blogs-img-js": () => import("./../../../src/pages/contact/BlogsImg.js" /* webpackChunkName: "component---src-pages-contact-blogs-img-js" */),
  "component---src-pages-contact-contact-component-js": () => import("./../../../src/pages/contact/ContactComponent.js" /* webpackChunkName: "component---src-pages-contact-contact-component-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-education-education-component-js": () => import("./../../../src/pages/education/EducationComponent.js" /* webpackChunkName: "component---src-pages-education-education-component-js" */),
  "component---src-pages-education-education-img-js": () => import("./../../../src/pages/education/EducationImg.js" /* webpackChunkName: "component---src-pages-education-education-img-js" */),
  "component---src-pages-education-index-js": () => import("./../../../src/pages/education/index.js" /* webpackChunkName: "component---src-pages-education-index-js" */),
  "component---src-pages-experience-experience-img-js": () => import("./../../../src/pages/experience/ExperienceImg.js" /* webpackChunkName: "component---src-pages-experience-experience-img-js" */),
  "component---src-pages-experience-experience-js": () => import("./../../../src/pages/experience/Experience.js" /* webpackChunkName: "component---src-pages-experience-experience-js" */),
  "component---src-pages-experience-index-js": () => import("./../../../src/pages/experience/index.js" /* webpackChunkName: "component---src-pages-experience-index-js" */),
  "component---src-pages-home-home-component-js": () => import("./../../../src/pages/home/HomeComponent.js" /* webpackChunkName: "component---src-pages-home-home-component-js" */),
  "component---src-pages-home-index-js": () => import("./../../../src/pages/home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-all-js": () => import("./../../../src/pages/projects/all.js" /* webpackChunkName: "component---src-pages-projects-all-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-projects-img-js": () => import("./../../../src/pages/projects/ProjectsImg.js" /* webpackChunkName: "component---src-pages-projects-projects-img-js" */),
  "component---src-templates-archive-archive-js": () => import("./../../../src/templates/archive/archive.js" /* webpackChunkName: "component---src-templates-archive-archive-js" */),
  "component---src-templates-post-post-js": () => import("./../../../src/templates/post/post.js" /* webpackChunkName: "component---src-templates-post-post-js" */),
  "component---src-templates-tag-tag-js": () => import("./../../../src/templates/tag/tag.js" /* webpackChunkName: "component---src-templates-tag-tag-js" */)
}

